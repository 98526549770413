/* eslint-disable no-console */

export default {
  name: 'myprize',
  data() {
    return {
      prizeList: [],
      orderList: [],
      openid: '',
      logintoken: '',
      kduuid: this.$route.query.kduuid,
      superChannel: this.$route.query.superChannel || 'KUAIDI100',
      subChannel: this.$route.query.subChannel || 'DEFAULT',
      isDone: false
    }
  },
  methods: {
    getPrizeList() {//获取我的奖品列表
      this.$http.post(`/apicenter/creditmall.do?method=myPrizesList`, {
        data: {
          openid: this.openid,
          logintoken: this.logintoken,
          kduuid: this.kduuid,
          token: ''
        },
        handleLogin: false
      }).then(res => {
        this.prizeList = res.data
        for (let i in this.prizeList) {
          this.timeClock(this.prizeList[i].lefttime, i)
        }
      })
    },
    getOrderList() {
      this.$http.get(`/e-commerce/order/list`, {
        data: {
          kduuid: this.kduuid,
          page: 1,
          limit: 10
        },
        handleLogin: false
      }).then(res => {
        this.orderList = res.data.list
      })
    },
    formatSeconds(value) {
      let result = parseInt(value)
      let h = Math.floor(result / 3600) < 10 ? Math.floor(result / 3600) : Math.floor(result / 3600)
      let m = Math.floor((result / 60 % 60)) < 10 ? Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
      let s = Math.floor((result % 60)) < 10 ? Math.floor((result % 60)) : Math.floor((result % 60))
      result = `${h}时${m}分${s}秒`
      this.isDone = true
      return result
    },
    timeClock(time, i) {
      this.timer = setInterval(() => {
        time = time - 1
        this.prizeList[i].lefttime = this.formatSeconds(time)
      }, 1000)
    },
    toDetail(id) {
      // this.$router.push({
      //   path: '/welfare/order',
      //   query: { id: id }
      // })
      location.href = 'https://m.kuaidi100.com/mexpress/welfare/order?id='+id
    },
    toOrder(item) {
      location.href = item.description + `&kduuid=` + this.kduuid + `&lotteryId=` + item.id + `&superChannel=` + this.superChannel + `&subChannel=` + this.subChannel + '&isFrom=myprize'
    }
  },
  mounted() {
    document.title = '我的奖品'
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.getPrizeList()
    this.getOrderList()
  }
}