/* eslint-disable no-console */

export default {
  name: 'order',
  data() {
    return {
      maskShow: false,
      orderId: this.$route.query.id,
      orderData: '',
      orderStatus: '',
      time: new Date().getHours(),
      productId: this.$route.query.productId,
      contactTel: '19927709793',
      defaultText: '订单已提交，稍后客服人员会电话联系您确认订单信息，请注意接听。祝您购物愉快'
    }
  },
  methods: {
    query() {
      window.location.href = 'https://m.kuaidi100.com/result.jsp?nu=' + this.orderData.logisticsId
    },
    getOrderDetail() {
      this.$http.get(`/e-commerce/order/load`, {
        data: {
          id: this.orderId
        }
      }).then(res => {
        this.orderData = res.data
        switch (res.data.orderStatus) {
          case 1:
            this.orderData.orderStatus = '待联系'
            break
          case 2:
            this.orderData.orderStatus = '待发货'
            break
          case 3:
            this.orderData.orderStatus = '下单中'
            break
          case 4:
            this.orderData.orderStatus = '发货失败'
            break
          case 5:
            this.orderData.orderStatus = '待取件'
            break
          case 6:
            this.orderData.orderStatus = '已寄出'
            break
          case 7:
            this.orderData.orderStatus = '已取消'
            break
        }
      })
    },
    contact() {
      let time = new Date().getHours()
      if (time < 10 || time > 18) {
        this.maskShow = true
        return
      }
    },
    closeMask() {
      this.maskShow = false
    }
  },
  mounted() {
    document.title = '订单详情'
    this.getOrderDetail()
    if (this.productId === '15359353') {//化妆刷
      this.contactTel = '13642964139'
    } else if (this.productId === '16101560') {//书本
      this.contactTel = '13552863721'
      this.defaultText = '订单已提交，会在48小时内为您安排发货，发货后会短信通知您，祝您购物愉快！'
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  }
}