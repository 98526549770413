/* eslint-disable no-console */

export default {
  name: 'welfare',
  data() {
    return {
      cHegiht: '',
      openid: '',
      prizeList: [],
      maskIdx: -1,
      background: 'none',
      ltimes: '',//剩余抽奖次数
      leftover: null,//每天抽奖次数
      couponListTemp: [],
      couponList: [],
      isExpanded: false,
      isLoading: false,
      maskShow: false,
      ruleShow: false,
      kduuid: this.$route.query.kduuid,
      logintoken: '',
      dstyle: {},
      danmuData: [],
      timeoverShow: false,
      unlotteryShow: false,
      lotteryShow: false,
      adShow: false,
      prizeData: {},
      superChannel: this.$route.query.superChannel || 'KUAIDI100',
      subChannel: this.$route.query.subChannel || 'DEFAULT',
      adObj: {},
      list: []
    }
  },
  methods: {
    lotteryList() {//转盘奖品列表
      this.$http.post('/apicenter/creditmall.do?method=lotteryList', {
        data: {
          openid: this.openid,
          logintoken: this.logintoken,
          kduuid: this.kduuid,
          token: 'token',
          activityno: 'BUSINESS'
        },
        handleLogin: false
      }).then(res => {
        let prizeList = []
        for (let i in res.data) {
          if (i < 9) {
            prizeList.push(res.data[i])
          }
        }
        this.prizeList = prizeList
        this.prizeList.push(this.prizeList[4])
      })
    },
    couponTap(item) {
      if (item.gotStatus === 0) {//领取
        this.welfareCenterGot(item)
      } else {//去使用
        window.location.href = item.url + `&kduuid=` + this.kduuid + `&lotteryId=` +
          item.id + `&superChannel=` + this.superChannel + `&subChannel=` + this.subChannel
      }
    },
    changeExpand() {
      if (this.isExpanded) {
        this.isExpanded = false
        let couponList = this.couponList
        this.couponList = []
        for (let i in couponList) {
          if (i < 4) {
            this.couponList.push(couponList[i])
          }
        }
      } else {
        this.isExpanded = true
        this.couponList = this.couponListTemp
      }
    },
    maskTransform() {
      this.timer = setInterval(() => {
        if (this.maskIdx < 8) {
          this.maskIdx += 1
        } else {
          this.maskIdx = -1
        }
      }, 100)
    },
    getLottery() {
      this.unlotteryShow = false
      if (this.ltimes == 0) {
        this.timeoverShow = true
        return
      }
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      this.maskTransform()
      setTimeout(() => {
        this.maskIdx = -1
        clearInterval(this.timer)
        this.lottery()
      }, 3000)
    },
    color(a, b) {
      if (b == 'bg') {
        if (a == '现金券') return '#FFE8C9'
        else if (a == '折扣券') return '#E4EFFF'
        else if (a == '立减券') return '#EEE2F6'
        else return '#FFE2D1'
      } else if (b == 'font') {
        if (a == '现金券') return '#ED9C00'
        else if (a == '折扣券') return '#3374D3'
        else if (a == '立减券') return '#923ACD'
        else return '#BD4C08'
      } else if (b == 'line') {
        if (a == '现金券') return 'rgba(237,156,0,0.5)'
        else if (a == '折扣券') return 'rgba(51,116,211,0.5)'
        else if (a == '立减券') return 'rgba(146,58,205,0.5)'
        return 'rgba(209,148,92,0.5)'
      }
    },
    lookRule() {
      this.maskShow = true
    },
    closeMask(str) {
      this.maskShow = false
      this.timeoverShow = false
      this.unlotteryShow = false
      this.lotteryShow = false
      this.adShow = false
      if (str) {//滚动到优惠券列表位置

      }
    },
    lotteryTime() {//剩余抽奖次数
      this.$http.post('/apicenter/creditmall.do?method=lotteryLeftover', {
        data: {
          openid: this.openid,
          logintoken: this.logintoken,
          activityno: 'BUSINESS',
          kduuid: this.kduuid
        },
        handleLogin: false
      }).then(res => {
        this.ltimes = res.data.ltimes
        this.leftover = res.data.leftover
      })
    },
    lottery() {//抽奖
      this.$http.post('/apicenter/creditmall.do?method=lottery', {
        data: {
          openid: this.openid,
          logintoken: this.logintoken,
          unionid: '',
          kduuid: this.kduuid,
          activityno: 'BUSINESS'
        },
        handleLogin: false
      }).then(res => {
        this.prizeData = res
        if (res.winPrize == 0) {
          this.unlotteryShow = true
        } else {
          this.lotteryShow = true
        }
        this.isLoading = false
        this.lotteryTime()
        this.getPrizeList()
      })
    },
    welfareCenterGot(item) {//领取精选优惠券
      this.$http.post('/apicenter/xcx.do?method=welfareCenterGot', {
        data: {
          openid: this.openid,
          token: '',
          id: item.id,
          kduuid: this.kduuid
        },
        handleLogin: false
      }).then(res => {
        if (res.status == 200) {
          item.gotStatus = 1
          this.$toast('领取成功')
          // this.welfareCenterList()
          item.gotStatus = 1
          location.href = item.url + `&kduuid=` + this.kduuid + `&lotteryId=` +
            item.id + `&superChannel=` + this.superChannel + `&subChannel=` + this.subChannel
        }
      })
    },
    welfareCenterList() {//精选优惠券列表
      this.$http.post('/apicenter/xcx.do?method=welfareCenterList', {
        data: {
          openid: this.openid,
          token: '',
          kduuid: this.kduuid
        },
        handleLogin: false
      }).then(res => {
        this.couponListTemp = res.data
        let couponList = res.data
        if (!this.isExpanded) {
          this.couponList = []
          for (let i in couponList) {
            if (i < 4) {
              this.couponList.push(couponList[i])
            }
          }
          console.log(this.couponList, 'this.couponList')
        }
      })
    },
    lotteryRecords() {//弹幕数据
      this.$http.post('/apicenter/creditmall.do?method=lotteryRecords', {
        data: {
          openid: this.openid,
          logintoken: this.logintoken,
          kduuid: this.kduuid,
          activityno: 'BUSINESS'
        },
        handleLogin: false
      }).then(res => {
        this.danmuData = res.data
      })
    },
    lotteryExchange() {//中奖后兑奖
      this.$http.post('/apicenter/creditmall.do?method=lotteryExchange', {
        data: {
          openid: this.openid,
          logintoken: this.logintoken,
          id: this.prizeData.data.id,
          kduuid: this.kduuid
        },
        handleLogin: false
      }).then(res => {
        if (res.status == 200) {
          this.$toast('领取成功')
          this.lotteryShow = false
          location.href = this.prizeData.data.linkurl + `&kduuid=` + this.kduuid + `&lotteryId=` +
            this.prizeData.data.id + `&superChannel=` + this.superChannel + `&subChannel=` + this.subChannel + `&productName=` + this.prizeData.data.title
        }
      })
    },
    toMyPrize() {
      this.$router.push({
        path: '/welfare/myprize',
        query: { kduuid: this.kduuid }
      })
    },
    getAd() {
      this.$http.post('/mobile/mobileapi.do?method=mainprofile', {
        data: {
          pos: 'h5lottery',
          city: '',
          openid: this.openid,
          province: '',
          isLogin: '',
          source: '',
          platform: 'm',
          isTest: 1
        },
        handleLogin: false
      }).then(res => {
        if (res.adslist.length > 0) {
          res.adslist.forEach(item => {
            if (item.pos === 'h5lottery') {
              this.adObj = item
              if (!this.getCookie('adShow')) {
                this.setCookie()
                this.adShow = true
              }
            }
          })
        }
      })
    },
    toGetPrize() {
      location.href = this.adObj.url + `&kduuid=` + this.kduuid + `&lotteryId=` +
        '' + `&superChannel=` + this.superChannel + `&subChannel=` + this.subChannel + `&productName=` + this.adObj.title
    },
    setCookie() {
      const cookieName = 'adShow'
      let cookieValue = true
      const exp = new Date()
      exp.setTime(exp.getTime() + 60 * 60 * 1000)//过期时间 1h
      document.cookie = cookieName + "=" + escape(cookieValue) + ";expires=" + exp.toGMTString()
    },
    getCookie(cname) {
      let name = cname + "="
      let ca = document.cookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i].trim()
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length)
      }
      return ""
    },
    getPrizeList() {//获取我的奖品列表
      this.$http.post(`/apicenter/creditmall.do?method=myPrizesList`, {
        data: {
          openid: this.openid,
          logintoken: this.logintoken,
          kduuid: this.kduuid,
          token: ''
        },
        handleLogin: false
      }).then(res => {
        this.list = res.data
      })
    }
  },
  mounted() {

  },
  computed: {
  },
  watch: {

  },
  created() {
    document.title = '福利中心'
    this.cHegiht = document.documentElement.clientHeight + 'px'
    this.lotteryList()
    this.lotteryTime()
    this.lotteryRecords()
    this.welfareCenterList()
    this.getAd()
    this.getPrizeList()
  }
}